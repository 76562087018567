import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { AfterViewInit, Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import SignaturePad from 'signature_pad'
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-grower-agro-charter-signature',
  templateUrl: './grower-agro-charter-signature.component.html',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, CommonModule],
})
export class GrowerAgroCharterSignatureComponent implements AfterViewInit {
  @ViewChild('growerSignPad') growerSignPadContainerElem: ElementRef
  @ViewChild('bndSignPad') bndSignPadContainerElem: ElementRef

  @ViewChild('growerSignPadCanvas') growerSignPadCanvasElem: ElementRef
  @ViewChild('bndSignPadCanvas') bndSignPadCanvasElem: ElementRef

  private growerSignPad: SignaturePad
  private bndSignPad: SignaturePad

  isGrowerSignatureModified: boolean = false
  isBndSignatureModified: boolean = false

  form: FormGroup

  constructor(
    public dialogRef: MatDialogRef<GrowerAgroCharterSignatureComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data?: {
      existingGrowerSignature: string
      existingBndSignature: string
    }
  ) {
    this.form = new FormGroup({
      acceptTerms: new FormControl(false, [Validators.requiredTrue]),
      growerSignature: new FormControl('', [Validators.required]),
      bndSignature: new FormControl('', []),
    })
  }

  ngAfterViewInit(): void {
    this.growerSignPad = new SignaturePad(this.growerSignPadCanvasElem?.nativeElement)
    this.bndSignPad = new SignaturePad(this.bndSignPadCanvasElem?.nativeElement)

    this.resizeCanvas(this.growerSignPad, this.growerSignPadCanvasElem.nativeElement)
    this.resizeCanvas(this.bndSignPad, this.bndSignPadCanvasElem.nativeElement)

    this.growerSignPadCanvasElem.nativeElement.addEventListener('pointerdown', () => {
      this.isGrowerSignatureModified = true
      this.form.patchValue({
        growerSignature: this.growerSignPad.isEmpty() ? '' : this.growerSignPad.toDataURL(),
      })
    })

    this.bndSignPadCanvasElem.nativeElement.addEventListener('pointerdown', () => {
      this.isBndSignatureModified = true
      this.form.patchValue({
        bndSignature: this.bndSignPad.isEmpty() ? '' : this.bndSignPad.toDataURL(),
      })
    })
  }

  get isGrowerSignatureValid(): boolean {
    return this.form.controls['growerSignature'].valid
  }

  get isBndSignatureValid(): boolean {
    const bndSignatureValue: string = this.form.controls['bndSignature'].value
    return !!bndSignatureValue
  }

  @HostListener('window:resize')
  onResize(): void {
    this.resizeCanvas(this.growerSignPad, this.growerSignPadCanvasElem.nativeElement)
    this.resizeCanvas(this.bndSignPad, this.bndSignPadCanvasElem.nativeElement)
  }

  private resizeCanvas(signaturePad: SignaturePad, canvas: HTMLCanvasElement): void {
    const ratio: number = 1 / 3
    canvas.width = canvas.offsetWidth
    canvas.height = Math.round(canvas.width * ratio)
    signaturePad.clear()
  }

  onClearGrowerSignature(): void {
    this.growerSignPad.clear()
    this.form.patchValue({
      growerSignature: '',
    })
    this.isGrowerSignatureModified = false
  }

  onClearBndSignature(): void {
    this.bndSignPad.clear()
    this.form.patchValue({
      bndSignature: '',
    })
    this.isBndSignatureModified = false
  }

  onCancel() {
    this.dialogRef.close()
  }

  onSave(): void {
    this.form.patchValue({
      growerSignature: this.growerSignPad.isEmpty() ? '' : this.growerSignPad.toDataURL(),
      bndSignature: this.bndSignPad.isEmpty() ? '' : this.bndSignPad.toDataURL(),
    })

    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }

    this.dialogRef.close({
      acceptTerms: this.form.value.acceptTerms,
      growerSignature: this.form.value.growerSignature,
      bndSignature: this.form.value.bndSignature,
    })
  }
}
