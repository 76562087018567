<div class="picture">
  <mat-dialog-content align="center" class="picture-content">
    <div class="picture-content-container">
      <mat-icon
        class="picture-content-arrow picture-content-arrow-left"
        svgIcon="left-arrow"
        *ngIf="hasPrevious()"
        (click)="previous()"
      ></mat-icon>
      <img #image src="{{ picturesUrl?.length > 0 ? picturesUrl[currentPictureIndex] : '' }}" />
      <mat-icon
        class="picture-content-arrow picture-content-arrow-right"
        svgIcon="right-arrow"
        *ngIf="hasNext()"
        (click)="next()"
      ></mat-icon>
    </div>
    <ao-loading-container *ngIf="loading">
      <ao-loading animation="spinner" size="lg"></ao-loading>
    </ao-loading-container>
  </mat-dialog-content>
</div>
