import { Component } from '@angular/core'

@Component({
  selector: 'ao-loading-container',
  template: '<ng-content></ng-content>',
  host: {
    class: 'flex justify-center z-10',
  },
})
export class AoLoadingContainerComponent {}
