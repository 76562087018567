<div class="location-mode">
  <h2 mat-dialog-title>{{ 'DIALOG.LOCATION_MODE.TITLE' | translate }}</h2>
  <mat-dialog-actions align="center" class="location-mode-actions">
    <button color="accent" mat-flat-button (click)="setAutomaticLocation()">
      {{ 'DIALOG.LOCATION_MODE.CURRENT' | translate }}
    </button>
    <button color="accent" mat-flat-button (click)="setManualLocation()" class="location-mode-actions-manual">
      {{ 'DIALOG.LOCATION_MODE.CHOOSE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
