import { CommonModule } from '@angular/common'
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  standalone: true,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  @Input() isChecked: boolean = false
  @Input() disabled: boolean = false
  @Output() switchChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: boolean) => void = () => {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => void = () => {}

  updateState() {
    if (this.disabled) return

    this.isChecked = !this.isChecked
    this.switchChange.emit(this.isChecked)
    this.onChange(this.isChecked)
    this.onTouched()
  }

  rollbackState(): void {
    this.isChecked = !this.isChecked
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  writeValue(value: boolean): void {
    this.isChecked = value
  }
}
