import { Injectable } from '@angular/core'
import {
  addField,
  addFieldFailure,
  addFieldSuccess,
  deleteField,
  deleteFieldError,
  deleteFieldSuccess,
  loadFields,
  loadFieldsFailure,
  loadFieldsSuccess,
  updateField,
  updateFieldFailure,
  updateFieldSuccess,
} from '../actions/field.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, map, mergeMap } from 'rxjs'
import { of } from 'rxjs'
import { FieldHttpParams, FieldService } from '@agroone-app/shared/field/services/field.service'
import { TranslateService } from '@ngx-translate/core'
import { ToastService } from '@agroone-front/shared'

@Injectable()
export class FieldEffects {
  constructor(
    private actions$: Actions,
    private fieldService: FieldService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  public getFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFields),
      debounceTime(100),
      mergeMap((params: FieldHttpParams) =>
        this.fieldService.getAll(params).pipe(
          map((fields) => loadFieldsSuccess({ fields })),
          catchError((e) => of(loadFieldsFailure({ error: `${e}` })))
        )
      )
    )
  )

  public deleteField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteField),
      mergeMap(({ fieldId }) =>
        this.fieldService.delete(fieldId).pipe(
          map(() => {
            this.toastService.showToastSuccess(this.translate.instant('FIELD.DELETE_SUCCESS'))
            return deleteFieldSuccess({ payload: { fieldId } })
          }),
          catchError((e) => {
            this.toastService.showToastError(this.translate.instant('FIELD.DELETE_FAILED'))
            return of(deleteFieldError({ error: `${e}` }))
          })
        )
      )
    )
  )

  public addField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addField),
      mergeMap(({ payload }) =>
        this.fieldService.save(payload.field).pipe(
          map((field) =>
            addFieldSuccess({
              payload: {
                field,
                loading: false,
              },
            })
          ),
          catchError((e) =>
            of(
              addFieldFailure({
                error: `${e}`,
                loading: false,
              })
            )
          )
        )
      )
    )
  )

  public updateField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateField),
      mergeMap(({ payload }) =>
        this.fieldService.update(payload.field).pipe(
          map((field) =>
            updateFieldSuccess({
              payload: {
                field,
                loading: false,
              },
            })
          ),
          catchError((e) =>
            of(
              updateFieldFailure({
                error: `${e}`,
                loading: false,
              })
            )
          )
        )
      )
    )
  )
}
