import { environment } from '@front-app-environments/environment'
import { NetworkService, SharedUserService } from '@agroone-front/shared'
import { Croptype, MsSqlPaginatedData, Region } from '@agroone/entities'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, catchError, map, mergeMap, Observable, of, throwError } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  private regionName: string
  private region: Region
  private regionsSubject: BehaviorSubject<Region[]> = new BehaviorSubject<Region[]>(null)
  private regionsUrl: string = `${environment.apiUrl}${environment.regions}`
  /**
   * Return the region of the current user
   */

  constructor(
    private http: HttpClient,
    private userService: SharedUserService,
    protected networkService: NetworkService
  ) {
    // Watch for user changes to update activeRegion
    this.userService.user.pipe(mergeMap(() => this.activeRegion.pipe(catchError(() => of())))).subscribe()
  }

  private _setActiveRegion() {
    this.region = this.allRegion?.find((r) => r.name === this.userService.currentUser.regionName)
    this.regionName = this.region?.name
  }

  public get allRegion(): Region[] {
    return this.regionsSubject.getValue()
  }

  get activeRegion(): Observable<Region> {
    if (this.region && this.regionName === this.userService.currentUser?.regionName) {
      return of(this.region)
    } else {
      if (this.userService.currentUser) {
        this._setActiveRegion()
        return of(this.region)
      } else {
        // Should never happen
        const err: Error = new Error('=========User not found')
        return throwError(() => err)
      }
    }
  }

  get currentActiveRegion(): Region {
    if (this.region && this.regionName === this.userService.currentUser?.regionName) {
      return this.region
    } else if (this.userService.currentUser?.regionName) {
      this._setActiveRegion()
      return this.region
    }
    return undefined
  }

  init(): Observable<Region[]> {
    return this.getAll()
  }

  public get(id: number): Observable<Region> {
    return this.http.get<Region>(`${this.regionsUrl}/${id}`).pipe(map((region: Region) => new Region(region)))
  }

  public getAll(): Observable<Region[]> {
    return this.http.get<MsSqlPaginatedData<Region>>(this.regionsUrl).pipe(
      map((regions) => {
        const dataRegion: Region[] = regions.data.map((region) => new Region(region))
        dataRegion.sort((x, y) => x.name.localeCompare(y.name))
        this.regionsSubject.next(dataRegion)
        return dataRegion
      })
    )
  }

  public getCroptypesOfActiveRegion(): Observable<Croptype[]> {
    return this.http.get<Croptype[]>(`${this.regionsUrl}/croptypes`)
  }
}
