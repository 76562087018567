<div class="grower-agro-charter-signature" [formGroup]="form">
  <h2 class="text-primary">{{ 'GROWER.PRODUCER_SIGNATURE' | translate }} <span class="text-error">*</span></h2>
  <div class="clear-grower-signature-pad flex justify-end">
    <button
      class="btn btn-error btn-outline px-2 m-0 h-[1.25rem] min-h-[1.25rem] text-sm"
      [disabled]="!isGrowerSignatureValid"
      (click)="onClearGrowerSignature()"
    >
      {{ 'DIALOG.BUTTON.CLEAR' | translate }}
    </button>
  </div>
  <div class="grower-signpad mt-1" #growerSignPad>
    <canvas class="grower-signpad-canvas w-full border border-primary" #growerSignPadCanvas></canvas>
  </div>
  <div class="mt-2">
    <span
      *ngIf="form.controls['acceptTerms'].invalid && form.controls['acceptTerms'].touched"
      class="text-error text-sm"
    >
      {{ 'DEFAULTS.REQUIRED_FIELD' | translate }}
    </span>
    <label class="cursor-pointer label flex justify-start p-0">
      <input type="checkbox" class="checkbox checkbox-primary" required [formControlName]="'acceptTerms'" />
      <span class="ml-2 text-sm">
        {{ 'GROWER.PRODUCER_CHECKBOX_LABEL' | translate }}
      </span>
    </label>
  </div>
  <span
    *ngIf="form.controls['growerSignature'].invalid && form.controls['growerSignature'].touched"
    class="text-error text-sm mt-1"
  >
    {{ 'DEFAULTS.REQUIRED_FIELD' | translate }}
  </span>

  <h2 class="text-primary mt-4">
    {{ 'GROWER.BND_REPRESENTATIVE' | translate }}
  </h2>

  <div class="clear-bnd-signature-pad flex justify-end">
    <button
      class="btn btn-error btn-outline px-2 m-0 h-[1.25rem] min-h-[1.25rem] text-sm"
      [disabled]="!isBndSignatureValid"
      (click)="onClearBndSignature()"
    >
      {{ 'DIALOG.BUTTON.CLEAR' | translate }}
    </button>
  </div>

  <div class="bnd-signpad mt-2" #bndSignPad>
    <canvas class="bnd-signpad-canvas w-full border border-primary" #bndSignPadCanvas></canvas>
  </div>
  <span
    *ngIf="form.controls['bndSignature'].invalid && form.controls['bndSignature'].touched"
    class="text-error text-sm mt-1"
  >
    {{ 'DEFAULTS.REQUIRED_FIELD' | translate }}
  </span>

  <div class="dialog-actions flex justify-center gap-2 mt-4">
    <button class="btn" (click)="onCancel()">
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
    <button class="btn btn-primary" [disabled]="form.invalid" (click)="onSave()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
  </div>
</div>
